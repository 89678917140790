.logoutContainer {
  position: fixed;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
  background-color: rgba(0, 0, 0, 0.6);

  .logoutCard {
    background-color: rgba($primary-lighter, 0.6);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.6);
    border: 1px solid rgba($primary-lighter, 0.3);
    padding: 10px 20px;
    border-radius: 25px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-shadow: 0 0 3px 3px rgba(0, 0, 0, 0.4);

    .title {
      font-family: Courgette;
      font-size: 36px;
      font-weight: 400;
      color: $text-secondary;
      user-select: none;
    }

    p {
      font-family: DancingScript;
      font-size: 20px;
      font-weight: 100;
      color: $text-secondary;
      user-select: none;
    }

    .logoutButtons {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 20px;
      margin-top: 20px;

      button {
        padding: 10px 20px;
        border-radius: 30px;
        border: none;
        box-shadow: 3px 2px 3px rgba(0, 0, 0, 0.4);
        font-family: Courgette;
        font-size: 22px;
        font-weight: 400;
        width: 200px;
        color: $text-primary;
        background-color: $primary-main;
        cursor: pointer;
        user-select: none;
        outline: none;

        &:active {
          background-color: lighten($primary-main, 5%);
          box-shadow: inset 0px 0px 3px rgba(0, 0, 0, 1);
        }
      }
    }
  }
}
