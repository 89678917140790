$primary-main: #cd561e;
$primary-light: #f6ba99;
$primary-lighter: #fcd787;
$primary-dark: #1b1b1b;

$primary-main-transparent: rgba(#cd561e, 0.7);
$primary-light-transparent: rgba(#f6ba99, 0.7);
$primary-lighter-transparent: rgba(#fcd787, 0.7);
$primary-dark-transparent: rgba(#1b1b1b, 0.7);

$text-primary: #ffffff;
$text-secondary: #000000;
$text-disabled: #757575;

$positive: #809b18;

$accent: #ffeb3b;
$alert: #784dee;

@mixin mobile {
  @media screen and (max-width: 480px) {
    @content;
  }

  @media screen and (max-height: 480px) {
    @content;
  }
}

@import 'styles/background.scss';
@import 'styles/menu.scss';
@import 'styles/login.scss';
@import 'styles/pizzaGrid.scss';
@import 'styles/table.scss';
@import 'styles/ingredients.scss';
@import 'styles/cart.scss';
@import 'styles/logout.scss';
@import 'styles/profileCard.scss';