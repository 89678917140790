.cornerMenu {
  position: fixed;
  top: 0;
  right: 0;
  display: flex;
  flex-direction: row;
  gap: 0px;
  z-index: 11;

  button {
    height: 55px;
    padding: 3px 20px;
    font-family: Courgette;
    font-size: 24px;
    font-weight: 400;
    outline: none;
    background-color: $primary-lighter-transparent;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.6);
    width: fit-content;
    cursor: pointer;
    user-select: none;

    &:active {
      background-color: lighten($primary-lighter, 5%);
      box-shadow: inset 0px 0px 3px rgba(0, 0, 0, 1);
    }

    &:disabled {
      color: $text-disabled;
      cursor: default;
      border: rgba(0, 0, 0, 1);
    }

    .shoppingCart {
      position: relative;
      font-size: 28px;
      top: 10%;
    }

    .menuBook {
      position: relative;
      font-size: 28px;
      top: 10%;
    }
  }

  button:first-child {
    border-bottom-left-radius: 20px;
  }
}

.profileMenu {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  margin: 15px 0 0 15px;
  width: 50px;
  height: 50px;
  border-radius: 1000px;
  background-color: $primary-lighter;
  cursor: pointer;
  user-select: none;
  z-index: 11;
  font-family: Courgette;
  font-size: 20px;
  box-shadow: 3px 1px 2px rgba(0, 0, 0, 0.7);
  outline: none;

  &:active {
    background-color: lighten($primary-lighter, 5%);
    box-shadow: inset 0px 0px 3px rgba(0, 0, 0, 1);
  }
}

.adminButton {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  bottom: 0;
  left: 0;
  height: 55px;
  padding: 3px 23px 3px 20px;
  font-family: Courgette;
  font-size: 24px;
  font-weight: 400;
  outline: none;
  background-color: $primary-lighter-transparent;
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.6);
  width: fit-content;
  cursor: pointer;
  user-select: none;
  border-top-right-radius: 20px;

  &:active {
    background-color: lighten($primary-lighter-transparent, 5%);
    box-shadow: inset 0px 0px 3px rgba(0, 0, 0, 1);
  }
}
