.ingredientsContainer {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.5) !important;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  display: block !important;
  z-index: 3;

  .ingredientsCard {
    width: 300px;
    height: fit-content;
    padding: 5px 20px 20px 20px;
    border-radius: 30px;
    background-color: $primary-lighter-transparent;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba($primary-lighter, 0.2);
    box-shadow: 0 2px 20px rgba(0, 0, 0, 0.8);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 10% auto auto auto;
    z-index: 3;

    .title {
      font-size: 2rem;
      font-weight: 400;
      user-select: none;
    }

    .spicy {
      font-size: 1.5rem;
      color: darken(red, 10%);
    }

    ul {
      margin: auto auto auto 0;
      width: fit-content;
      list-style-image: url(../assets/svg-path.svg);

      li {
        font-weight: 400;
        color: $primary-dark;
        font-size: 20px;
        margin-left: -10%;
        font-family: Lato-Regular;

        &::selection {
          color: $primary-lighter;
          background-color: $primary-main;
        }
      }
    }
  }
}
