.formContainer {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .title {
    margin-top: -10%;
    font-family: Courgette;
    font-size: 100px;
    font-weight: 200;
    color: $text-primary;
    margin-bottom: 1rem;
    user-select: none;
  }

  .formWrapper {
    background-color: $primary-light;
    padding: 20px 80px;
    border-radius: 20px;
    box-shadow: 0 0 20px 0 rgba(230, 81, 0, 0.4);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;

    .title {
      font-family: Courgette;
      font-size: 40px;
      font-weight: 400;
      color: $text-secondary;
      margin-top: -5%;
      margin-bottom: -3%;
      user-select: none;
    }

    form {
      display: flex;
      flex-direction: column;
      gap: 15px;
      width: 100%;

      input {
        padding: 10px 15px;
        border-radius: 30px;
        border: none;
        box-shadow: 3px 2px 3px rgba(0, 0, 0, 0.4);
        font-family: DancingScript;
        font-size: 20px;
        font-weight: 500;
        color: $text-secondary;
        background-color: white;

        &::placeholder {
          color: $text-disabled;
          font-family: DancingScript;
          text-transform: capitalize;
        }
      }

      button {
        padding: 10px 15px;
        border-radius: 30px;
        width: fit-content;
        border: none;
        margin: auto;
        font-family: Courgette;
        font-size: 22px;
        font-weight: 400;
        color: $text-primary;
        box-shadow: 3px 2px 3px rgba(0, 0, 0, 0.4);
        background-color: $primary-main;
        cursor: pointer;
        transition: all 0.3s ease-in-out;
        user-select: none;

        &:active {
          background-color: lighten($primary-main, 5%);
          box-shadow: inset 0px 0px 3px rgba(0, 0, 0, 1);
        }
      }
    }

    p {
      font-family: 'DancingScript';
      font-size: 18px;
      font-weight: 500;
      color: $text-secondary;
      margin: 0;
      user-select: none;

      a {
        color: black;
        user-select: none;
      }
    }
  }
}
