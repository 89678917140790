body {
  margin: 0;
  height: 100vh;
  font-family: -apple-system, 'Metal', 'Lato-Regular', BlinkMacSystemFont,
    'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: flex;
  flex-direction: column;
  background-image: url(./assets/bg.jpg);
  background-size: 40%;
  background-position-x: center;
  background-repeat: repeat;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'Metal';
  src: url(./assets/Metal-Regular.ttf);
}

@font-face {
  font-family: 'Luxurious';
  src: url(./assets/LuxuriousScript-Regular.ttf);
}

@font-face {
  font-family: 'Calligraffitti';
  src: url(./assets/Calligraffitti-Regular.ttf);
}

@font-face {
  font-family: 'Courgette';
  src: url(./assets/Courgette-Regular.ttf);
}
