.tableContainer {
  max-height: 95%;
  display: flex;
  align-items: center;
  flex-direction: column;

  .tableWrapper {
    margin-top: 5%;
    max-height: 75vh;
    height: fit-content;
    max-width: 80vw;
    width: fit-content;
    padding: 20px;
    border-radius: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;

    @include mobile {
      border-radius: 0;
      max-width: 89vw;
      background: #00000000;
      box-shadow: none;
    }

    .title {
      font-family: DancingScript;
      text-shadow: 3px 2px 2px black;
      font-size: 2rem;
      font-weight: 600;
      color: $text-primary;

      @include mobile {
        display: none;
      }
    }

    .tableCard {
      border-radius: 10px;
      background-color: $primary-light;
      height: fit-content;
      // overflow-y: scroll;

      @include mobile {
        border-radius: 0;
      }

      table {
        width: max-content;
        background-color: white;
        border: 5px solid $primary-main;
        height: fit-content;

        thead {
          display: block;
          margin: 5px;
          width: fit-content;

          tr {
            background-color: $primary-dark;
            position: sticky;

            th {
              width: 8vw;
              padding: 5px 15px;
              color: $text-primary;
              text-align: left;
              font-weight: bold;
              font-size: 24px;
              font-family: 'DancingScript';
              user-select: none;
              @include mobile {
                font-size: 16px;
              }
            }
          }
        }

        tbody {
          display: block;
          max-height: 60vh;
          overflow-y: scroll;
          width: fit-content;
          margin-left: 5px;

          tr {
            border-bottom: 1px solid $primary-dark;

            &:hover {
              background-color: $primary-light;
              cursor: pointer;
            }

            td {
              min-width: 8vw;
              width: fit-content;
              padding: 12px 15px;
              font-size: 18px;
              font-family: 'DancingScript';
              font-weight: 400;
              color: $text-secondary;
              text-overflow: ellipsis;
              word-wrap: break-word;
            }
          }
        }
      }
    }
  }
}
