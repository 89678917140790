.cartContainer {
  display: block;

  .cartCard {
    height: fit-content;
    max-height: 50%;
    width: 850px;
    display: flex;
    background-color: rgba($primary-dark, 0.4);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(lighten($primary-dark, 30%), 0.3);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.6);
    align-items: center;
    flex-direction: column;
    border-radius: 30px;
    margin: 10% auto auto auto;
    padding: 1% 0 3% 0;

    .title {
      font-size: 3.5rem;
      color: $text-primary;
      font-weight: 400;
      -webkit-text-stroke: rgba($primary-dark, 0.3) 2px;
      text-shadow: 2px 2px 4px rgba($primary-dark, 0.6);
    }

    .orderContainer {
      overflow-y: scroll;
      display: flex;
      flex-direction: column;
      padding: 5px;

      .orderRow {
        display: grid;
        width: 80%;

        .comment {
          font-size: 1.5rem;
          text-align: left;
          grid-column: 1;
          grid-row: 1;
        }

        img {
          // height: 8vh;
          left: 0px;
          width: 200px;
          grid-column: 1;
          grid-row: 1;
        }

        span {
          height: 8vh;
          font-size: 1.5rem;
          width: 200px;
          text-align: left;
          grid-column: 2;
          grid-row: 1;
        }

        div {
          width: 400px;
          height: 8vh;
          grid-column: 3;
          grid-row: 1;
        }
      }
    }
  }
}
