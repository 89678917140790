.background {
  position: absolute;
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  z-index: -1;

  .title {
    font-family: 'Luxurious';
    font-size: 800%;
    font-weight: 200;
    color: $text-primary;
    text-shadow: 2px 2px 3px black;
    margin: 2% auto auto auto;
    user-select: none;
    -moz-text-size-adjust: none;
    -webkit-text-size-adjust: none;
    -ms-text-size-adjust: none;
  }
}
