.pizzaGridContainer {
  display: flex;
  height: 100vh;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .pizzaGrid {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    max-width: 70%;
    transition: all 0.5s;

    @include mobile {
      grid-template-columns: repeat(1, 1fr);
    }

    .pizzaCard {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      border-radius: 20px;
      // box-shadow: 0 0 20px 0 rgba(230, 81, 0, 0.2);
      box-shadow: 0 4px 20px rgba(0, 0, 0, 0.7);
      background-color: rgba($primary-lighter, 0.6);
      backdrop-filter: blur(5px);
      -webkit-backdrop-filter: blur(5px);
      border: 1px solid rgba(lighten($primary-light, 20%), 0.1);
      margin: 1% 2%;
      height: fit-content;
      width: 220px;
      padding-bottom: 3px;
      overflow: clip;
      user-select: none;
      z-index: 2;
      transition: all 0.5s;

      .title {
        font-family: 'Courgette';
        font-size: 20px;
        font-weight: 400;
        color: $text-secondary;
        user-select: none;

        &::selection {
          color: $primary-lighter;
          background-color: $primary-main;
        }
      }

      img {
        width: 100%;
        height: 85%;
        z-index: 2;
        user-select: none;
        cursor: pointer;

        &:active {
          filter: brightness(1.2);
        }
      }

      .selected {
        filter: contrast(0.7);
      }

      .button {
        position: absolute;
        width: fit-content;
        outline: none;
        border: none;
        font-size: 200%;
        font-weight: 600;
        color: black;
        background-color: transparent;
        cursor: pointer;
        transition: all 0.3s ease-in-out;
        z-index: 2;
      }

      .favouritesButton {
        margin: -110px auto auto -165px;
      }

      .detailsButton {
        margin: 132px auto auto 165px;
      }

      .selectedIcon {
        cursor: default;
        font-size: 28px;
        margin: -115px auto auto 150px;
      }

      .hiddenIcon {
        display: none;
      }

      .check {
        fill: green;
      }

      .remove {
        fill: #880000;
      }
    }

    .selected > :not(.button) {
      filter: contrast(0.7);
    }
  }

  .orderButton {
    position: fixed;
    width: fit-content;
    outline: none;
    background-color: $primary-main;
    border: none;
    padding: 5px 20px;
    border-radius: 1000px;
    font-size: 32px;
    bottom: 10%;
    font-family: Courgette;
    font-weight: 400;
    color: $text-primary;
    box-shadow: 3px 1px 2px rgba(0, 0, 0, 0.7);
    cursor: pointer;
    z-index: 10;
    user-select: none;

    &:active {
      background-color: lighten($primary-main, 5%);
      box-shadow: inset 0px 0px 3px rgba(0, 0, 0, 1);
    }

    &:disabled {
      background-color: darken($primary-main, 20%);
      box-shadow: none;
      color: $text-disabled;
      cursor: default;
    }
  }

  .orderFavouritesButton {
    position: fixed;
    width: fit-content;
    outline: none;
    background-color: $primary-main;
    border: none;
    padding: 5px 20px;
    border-radius: 1000px;
    font-size: 24px;
    bottom: 10%;
    right: 10%;
    font-family: Courgette;
    font-weight: 400;
    color: $text-primary;
    transition: all 0.05s ease-in-out;
    box-shadow: 3px 1px 2px rgba(0, 0, 0, 0.7);
    cursor: pointer;
    z-index: 10;
    user-select: none;

    &:active {
      background-color: lighten($primary-main, 5%);
      box-shadow: inset 0px 0px 3px rgba(0, 0, 0, 1);
    }

    &:disabled {
      background-color: darken($primary-main, 20%);
      box-shadow: none;
      color: $text-disabled;
      cursor: default;
    }
  }
}
